import { db, storage } from 'zFirebaseConfigs/firebase';
import { collection, query, getDocs, where, doc, addDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, deleteObject, updateMetadata, listAll } from 'firebase/storage';

//Utils
import { getTimestamp } from '../utils/timeManager';
import { getImageExtension } from 'utils/imageUtils';

//Create menu item
export const createMenuItem = async (menuItem) => {
  try {
    const docRef = await addDoc(collection(db, 'menu_items'), menuItem);
    return docRef.id;
  } catch (error) {
    console.error(`Error creating menu item: ${error.message}`);
    throw error;
  }
};

//Fetch menu items by company ID
export const fetchMenuItemsByCompanyID = async (companyID) => {
  try {
    const menuItems = [];
    const querySnapshot = await getDocs(query(collection(db, 'menu_items'), where('companyID', '==', companyID)));
    querySnapshot.forEach((doc) => {
      menuItems.push({ id: doc.id, ...doc.data() });
    });

    return menuItems;
  } catch (error) {
    console.error(`Error fetching menu items: ${error.message}`);
    throw error;
  }
};

//Delete menu item by ID
export const deleteMenuItem = async (menuItemID) => {
  try {
    await deleteDoc(doc(db, 'menu_items', menuItemID));
  } catch (error) {
    console.error(`Error deleting menu item: ${error.message}`);
    throw error;
  }
};

//Upload MenuItem image to storage
export const uploadMenuItemImage = async (companyId, menuItemId, file) => {
  try {
    if (!file) return '';
    const extension = getImageExtension(file);
    const logoFolderPath = `${companyId}/menu_items`;
    // TODO: chante image type if it is an png
    const filePath = `${logoFolderPath}/${menuItemId}_${getTimestamp()}.${extension}`;
    const mountainImagesRef = ref(storage, filePath);

    const uploadTask = uploadBytesResumable(mountainImagesRef, file);
    await uploadTask;

    // Update metadata to set cache control
    const metadata = {
      cacheControl: 'public, max-age=31556926'
    };
    await updateMetadata(mountainImagesRef, metadata);
    return filePath;
  } catch (error) {
    console.error(`Error uploading MenuItem imageUrl: ${error.message}`);
    throw error;
  }
};

//Delete MenuItem image from storage
export const deleteMenuItemImage = async (companyId, menuItemId) => {
  try {
    const menuItemsFolderPath = `${companyId}/menu_items/`;
    const menuItemsFolderRef = ref(storage, menuItemsFolderPath);
    const existingImages = await listAll(menuItemsFolderRef);

    for (const itemRef of existingImages.items) {
      if (itemRef.name.includes(menuItemId)) {
        await deleteObject(itemRef);
      }
    }
  } catch (error) {
    console.error(`Error deleting menu item images: ${error.message}`);
    throw error;
  }
};

export const updateMenuItem = async (id, updatedData) => {
  try {
    const docRef = doc(db, 'menu_items', id);
    await updateDoc(docRef, updatedData);
    return updatedData;
  } catch (error) {
    console.error(`Error updating menu item: ${error.message}`);
    throw error;
  }
};

export const deleteAllItems = async (companyId) => {
  try {
    const querySnapshot = await getDocs(query(collection(db, 'menu_items'), where('companyID', '==', companyId)));
    querySnapshot.forEach((doc) => {
      deleteMenuItem(doc.id);
    });
  } catch (error) {
    console.error(`Error deleting all items: ${error.message}`);
    throw error;
  }
};
